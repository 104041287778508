import React from 'react'

import './styles.scss'

export function PageNotFound () {
  return (
    <div className='page-not-found'>
      404
    </div>
  )
}
